import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { useMedia } from 'hooks/useMedia'
import { Link } from 'elements/Link/Link'
import styles from '../Search.module.css'
import React from 'react'
import { ConfigurableProduct } from '@magentoTypes'
import { Button } from 'elements/Button/Button'
import { SearchProductCard } from './SearchProductCard'
import { HelpLinks } from './HelpLinks'
import { Headline } from '~/elements/Headline/Headline'
import { SearchTermProductCard } from '~/modules/SearchResultPage/components/SearchTermProductCard'
import { useKlevuQuickSearch } from '~/hooks/useKlevuQuickSearch'
import { useProductsOfPopularSearchTerms } from '~/hooks/useProductsOfSearchTerms'
import { ResultsLoader } from './SearchLoading'
import { Media } from '~/lib/Media'

type Props = {
  searchValue: string
  numberOfProductsShown: number
}

export const ResultsView = ({ numberOfProductsShown, searchValue }: Props) => {
  const { products, suggestionTerms, loading, noResults } = useKlevuQuickSearch(
    searchValue,
    numberOfProductsShown,
  )
  const isDesktop = useMedia('lgx')

  const { data: productsOfPopularSearchTerms } = useProductsOfPopularSearchTerms(
    !!(searchValue && !products?.length && !loading),
  )

  const { t } = useTranslation()

  if (searchValue && !products?.length && loading && !noResults) {
    return <ResultsLoader gridColumnsNumber={numberOfProductsShown / 2} />
  }
  if (searchValue && !products?.length && !loading && noResults) {
    return (
      <div className="h-fit w-full">
        <div className="flex w-full flex-col">
          <Headline
            className={clsx(
              'mt-12 mb-0 flex min-h-[56px] w-full items-center justify-center px-4 text-center lg:mt-4 lg:mb-2',
            )}
            type="h2"
          >
            {`${t('No search results for')} "${searchValue}"`}
          </Headline>
          <p className="mb-[30px] text-center lg:text-h4-desktop-large">{`${t(
            'Please try out a different search term',
          )}`}</p>
          <h2 className="ml-4 mb-6 text-h4-mobile lg:ml-20 lg:text-h4-desktop-large">
            {t('Popular search terms')}
          </h2>
        </div>
        <ul
          id="products-grid"
          className={clsx('grid w-full grid-cols-2 lg:grid-cols-4')}
          style={{ gridTemplateColumns: `repeat(2, minmax(0, 1fr)) lg:repeat(4, minmax(0, 1fr))` }}
        >
          {productsOfPopularSearchTerms?.length
            ? productsOfPopularSearchTerms?.map((productTermPair, i) =>
                productTermPair?.product && productTermPair?.term ? (
                  <li key={productTermPair.term}>
                    <SearchTermProductCard
                      hasBorderTop={i + 1 <= 4}
                      hasBorderRight={isDesktop ? (i + 1) % 4 !== 0 : (i + 1) % 2 !== 0}
                      product={productTermPair.product as ConfigurableProduct}
                      hasPriority={isDesktop ? i <= 3 : i <= 1}
                      colCount={4}
                      searchTerm={productTermPair.term}
                      isLastTwo={i >= 2}
                    />
                  </li>
                ) : null,
              )
            : null}
        </ul>
      </div>
    )
  }
  return (
    <>
      <div className="lgx:w-1/4">
        <>
          {suggestionTerms.length ? (
            <>
              <h2 className={clsx('hidden lgx:block', styles.searchHeading)}>
                {t('Search suggestions')}
              </h2>

              <ul className="mb-12 lgx:mb-16">
                {suggestionTerms?.map((suggestion: string, i: number) => (
                  <li key={i} className={styles.termLink}>
                    <Link
                      isUnderlinedOnHover
                      title={`search-${suggestion.replace('<b>', '').replace('</b>', '')}`}
                      href={`/search?q=${suggestion.replace('<b>', '').replace('</b>', '')}`}
                    >
                      <div
                        className="text-p"
                        dangerouslySetInnerHTML={{ __html: suggestion }}
                      ></div>
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </>
        <div className="hidden lgx:block">
          <HelpLinks />
        </div>
      </div>
      <div
        className={clsx(
          'relative h-fit w-full lgx:ml-12',
          !suggestionTerms.length && 'mt-20 lgx:mt-0',
        )}
      >
        <Button
          ctaButtonStyle="MediumText"
          className="absolute right-6 lgx:mr-0 lgx:mt-1 lgx:block"
          title="all-products"
          href={`/search?q=${searchValue}`}
        >
          {t('View all')}
        </Button>
        <h2
          className={clsx(
            'border-b border-grey-light pb-8 lgx:border-b-0 lgx:pb-0',
            styles.searchHeading,
          )}
        >
          {t('Products')}
        </h2>
        <ul className={clsx(styles.productGrid)}>
          {products.map((product: any, i: number) => (
            <SearchProductCard
              index={i}
              key={product.id}
              product={product}
              totalProdNumber={numberOfProductsShown}
              actualProdNumber={products.length}
            />
          ))}
        </ul>
      </div>
      <Media lessThan="lgx">
        <HelpLinks />
      </Media>
    </>
  )
}
