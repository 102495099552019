import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import styles from '../Search.module.css'
import { useNavigationQuery } from '@contentfulTypes'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

export const HelpLinks = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const helpLinksData = data?.pageCollection?.items?.[0]?.searchHelpLinksCollection

  return (
    <>
      <h2 className={clsx('mt-8 mb-4 lgx:mt-0 lgx:mb-0', styles.searchHeading)}>
        {t('Can we help you? ')}
      </h2>
      <menu className="flex flex-col gap-4 py-6">
        {helpLinksData?.items.map((linkItem, i) => (
          <li key={`${linkItem?.sys.id}_${i}`}>
            <ContentfulButton {...linkItem} ctaStyle="MediumText" />
          </li>
        ))}
      </menu>
    </>
  )
}
