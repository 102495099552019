import { Link } from 'elements/Link/Link'
import clsx from 'clsx'
import { NavigationElement, NavigationElementWithSubElements } from '@contentfulTypes'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'

type Props = {
  item:
    | Omit<NavigationElement, 'contentfulMetadata' | 'sys'>
    | Omit<NavigationElementWithSubElements, 'contentfulMetadata' | 'sys'>
  id?: string
  className?: string
  disableUnderlineOnHover?: boolean
  boldUnderlineOnHover?: boolean
  forceSameWeight?: boolean
  naviLevel?: string
  onClick?: () => void
}

export const NavItem = ({
  item,
  id,
  className,
  disableUnderlineOnHover,
  boldUnderlineOnHover,
  forceSameWeight,
  naviLevel,
  onClick,
}: Props) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { dataLayer } = useGTM()

  const onNavItemClick = () => {
    dataLayer(DataLayerEnum.EVENT, 'nav_item_click', {
      dataLayer: {
        method: 'navigation',
        navigation_level: naviLevel,
        navigation_item: item?.title ? item?.title : item.subline,
      },
    })
  }

  return (
    <>
      {item ? (
        item?.pageContent?.slug ? (
          <Link
            id={id}
            href={item?.pageContent.slug as string}
            className={clsx(
              'flex items-center text-primary-black duration-75',
              boldUnderlineOnHover ? '!whitespace-nowrap' : 'border-b border-primary-white',
              !disableUnderlineOnHover && 'hover:border-primary-black',
              forceSameWeight ? '!font-normal' : '',
              className,
            )}
            prefetch={false}
          >
            <div
              onClick={onNavItemClick}
              className={clsx(
                item?.highlightedColor
                  ? naviLevel === '1st_level'
                    ? 'px-1.5 py-1'
                    : 'px-2.5'
                  : '',
                {
                  'bg-[#F3D750]': item?.highlightedColor === 'Yellow',
                  'bg-[#BBDEDD]': item?.highlightedColor === 'Light-blue',
                },
              )}
            >
              {item?.title}
            </div>
          </Link>
        ) : (
          <button
            id={id}
            className={clsx(
              'flex cursor-pointer items-center text-primary-black duration-75 active:font-normal',
              boldUnderlineOnHover ? '!whitespace-nowrap' : 'border-b border-primary-white',
              !disableUnderlineOnHover && 'hover:border-primary-black',
              forceSameWeight ? '!font-normal' : '',
              className,
            )}
          >
            <div
              className={clsx(
                item?.highlightedColor && naviLevel === '1st_level' ? 'px-1.5 py-1' : '',
                {
                  'bg-[#F3D750]': item?.highlightedColor === 'Yellow',
                  'bg-[#BBDEDD]': item?.highlightedColor === 'Light-blue',
                },
              )}
              onClick={onNavItemClick}
            >
              {item?.title}
            </div>
          </button>
        )
      ) : null}
    </>
  )
}
