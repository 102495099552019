import { Link } from 'elements/Link/Link'
import { useTranslation } from 'next-i18next'
import { useMedia } from 'hooks/useMedia'
import { Image } from '@next/image'
import clsx from 'clsx'

type Props = {
  product: any
  index: number
  noBorderTopBottom?: boolean
  totalProdNumber: number
  actualProdNumber: number
  isRecentlyViewedProduct?: boolean
}

export const SearchProductCard = ({
  product,
  index,
  noBorderTopBottom,
  totalProdNumber,
  actualProdNumber,
  isRecentlyViewedProduct,
}: Props) => {
  const { t } = useTranslation()
  const isDesktop = useMedia('lgx')
  return (
    <li
      className={clsx(
        'relative flex h-[300px] flex-col items-center justify-center border-b border-r border-grey-border px-8 pt-10 pb-40 lg:h-[350px] lgx:h-[300px] lgx:border-r-0 2xl:h-[340px]',
        (totalProdNumber / 2 > actualProdNumber || noBorderTopBottom) &&
          'border-t-0 lgx:border-b-0',
        isRecentlyViewedProduct &&
          (isDesktop
            ? 'border-b-0 lgx:border-t-0'
            : index + 1 > totalProdNumber / 2 || actualProdNumber <= totalProdNumber / 2
            ? 'border-b-0'
            : ''),
        !isRecentlyViewedProduct
          ? isDesktop && index + 1 > totalProdNumber / 2 && 'border-b-0 lgx:border-b-0'
          : '',
        ((index + 1) % totalProdNumber === 0 ||
          (index + 1) % totalProdNumber === totalProdNumber / 2) &&
          'border-r-0',
      )}
    >
      <div className="relative">
        <Link
          href={product.url}
          title={product.url}
          className={
            'transition-height group relative top-6 block h-[150px] w-[150px] max-w-full overflow-hidden duration-500 ease-in-out sm:top-8 md:top-8 md:h-[160px] md:max-h-full lg:h-[180px] lgx:top-0 lgx:h-[155px] lgx:w-[120px]'
          }
        >
          <Image
            className="absolute scale-105 opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100"
            src={product.image}
            alt={'img'}
            fill
            style={{
              objectPosition: 'center',
              objectFit: 'contain',
            }}
            placeholder={'blur'}
            sizes="(min-width: 768px) 12vw, 27vw"
          />

          <Image
            className="opacity-100 transition-opacity duration-500 ease-in-out group-hover:opacity-0"
            src={product.image}
            alt={'img'}
            fill
            placeholder={'blur'}
            style={{
              objectPosition: 'center',
              objectFit: 'contain',
            }}
            sizes="(min-width: 768px) 12vw, 27vw"
          />
        </Link>
      </div>
      <Link
        href={product.url}
        title={product.url}
        className="absolute top-[180px] sm:top-[180px] md:top-[190px] lg:top-[240px] lgx:top-[160px] 2xl:top-[180px]"
      >
        <p className={clsx('mt-6 overflow-hidden px-4 text-center font-medium line-clamp-2')}>
          {product.name}
        </p>
        <p className={clsx('text-center')}>{`${t('from')} ${product.startPrice}${
          product.currency
        }`}</p>
      </Link>
    </li>
  )
}
