import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Logo } from '../components/Logo'
import { Link } from '../../../elements/Link/Link'
import { MobileButtonBar } from './components/MobileButtonBar'
import { Search } from '../../Search/Search'
import { MobileFlyout } from './components/MobileFlyout'

type Props = {}

export const MobileHeader = ({}: Props) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 32)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <header
      className={clsx(
        'grid-rows-2 bg-primary-white print:hidden lgx:hidden',
        hasScrolled && 'shadow-[0px_0px_24px_rgba(0,0,0,0.15)]',
      )}
    >
      <div className="h-[110px] px-4 py-3">
        <div className="mb-3 grid grid-cols-[auto_1fr_auto] gap-4">
          <MobileFlyout />
          <Link href="/" className="block">
            <Logo className="inline w-[97px] align-top" />
          </Link>
          <MobileButtonBar />
        </div>
        <Search />
      </div>
    </header>
  )
}
