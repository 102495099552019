import { useTranslation } from 'next-i18next'
import { Image } from '~/elements/Image/Image'
import { useRouter } from 'next/router'
import { UIContext } from '~/lib/Context/UIStore'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useMedia } from 'hooks/useMedia'
import { lockBodyScroll, freeBodyScroll } from '../../lib/scrollLock'
import { Icon } from '../../elements/Icon/Icon'
import useLocalStorage from 'hooks/useLocalStorage'
import clsx from 'clsx'
import { SearchMobileFlyout } from './components/SearchMobileFlyout'

export const Search = ({}) => {
  const {
    desktopNaviDispatch,
    mobileFlyoutToggle,
    searchValue,
    setSearchValue,
    searchToggle: isOpen,
    setSearchToggle: setIsOpen,
  } = useContext(UIContext)

  const { t } = useTranslation()
  const router = useRouter()
  const searchOverlayRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [recentlyViewedIds] = useLocalStorage('recentlyViewedProducts', '')
  const [currentStoredSearchTerms] = useLocalStorage('recentlySearchedTerms', '')
  const isTablet = useMedia('md')
  const isDesktop = useMedia('lgx')
  const is2XLDesktop = useMedia('2xl')

  const { q } = router.query

  const handleInputFocus = () => {
    setIsInputFocused(true)
    setIsOpen?.(true)
    lockBodyScroll()
  }

  useEffect(() => {
    if (mobileFlyoutToggle) {
      setIsOpen?.(false)
    }
  }, [mobileFlyoutToggle, setIsOpen])

  useEffect(() => {
    if (q) {
      setIsOpen?.(false)
      setSearchValue?.('')
      return freeBodyScroll()
    }
  }, [router.asPath, q, setSearchValue, setIsOpen])

  useEffect(() => {
    function handleClickOutside(e: Event) {
      if (e.target instanceof HTMLElement && e.target.dataset.id === 'search-input') {
        return
      }
      if (
        searchOverlayRef.current &&
        e.target instanceof Node &&
        !searchOverlayRef.current.contains(e.target) &&
        e.target instanceof HTMLElement &&
        e.target.dataset.id !== 'search-submit'
      ) {
        setIsOpen?.(false)
        return freeBodyScroll()
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
      return freeBodyScroll()
    }
  }, [setIsOpen])

  const getNumberOfProductsShown = () => {
    return is2XLDesktop ? 12 : isDesktop ? 8 : isTablet ? 6 : 8
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchValue) {
      await router.push(`/search?q=${searchValue}`, undefined, {
        scroll: true,
      })
      if (inputRef && inputRef.current) {
        inputRef.current.blur()
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      desktopNaviDispatch?.({ type: 'set-secondLevelToggle', payload: false })
    }
  }, [desktopNaviDispatch, isOpen])

  const renderInput = () => {
    return (
      <form
        className={clsx('z-1 relative h-full w-full')}
        onSubmit={onSubmit}
        onBlur={() => setIsInputFocused(false)}
        onMouseLeave={() => inputRef?.current?.blur()}
      >
        <input
          type="text"
          data-id="search-input"
          placeholder={t('Search')}
          className={clsx(
            'absolute right-0 left-0 top-0 bottom-0 h-[42px] w-full appearance-none rounded-md border-0 bg-grey-light font-dm-sans text-base placeholder-grey-dark focus:ring-0',
            searchValue ? 'pl-[42px]' : 'pl-4',
          )}
          ref={inputRef}
          value={searchValue}
          onChange={(e) => setSearchValue?.(e.target.value)}
          onFocus={() => handleInputFocus()}
        ></input>
        <button
          type="submit"
          data-id="search-submit"
          className={clsx(
            'absolute top-[7px] lgx:top-[9px]',
            searchValue ? 'left-[14px]' : 'right-3',
          )}
          title={t('Search')}
        >
          <Image
            src={require('../../public/images/Icons/search.svg')}
            width={24}
            height={24}
            placeholder="empty"
            alt="search-icon"
          />
        </button>
        {searchValue ? (
          <button
            id="search-close"
            type="button"
            onClick={(e) => {
              setSearchValue?.('')
              setIsOpen?.(false)
              freeBodyScroll()
            }}
            className={clsx('absolute top-[7px] right-3 lgx:top-[9px]')}
            title={t('Close')}
          >
            <Icon name="cross" />
          </button>
        ) : null}
      </form>
    )
  }
  return (
    <div>
      <div className={clsx('lgx:relative lgx:h-[42px] lgx:w-[245px]')}>{renderInput()}</div>
      {isDesktop ? null : (
        <>
          {isOpen && (
            <>
              <div
                ref={searchOverlayRef}
                className={clsx('fixed right-0 left-0 top-[157px] z-30 flex')}
              >
                <SearchMobileFlyout
                  onClose={() => {
                    setIsOpen?.(false)
                    freeBodyScroll()
                  }}
                  searchValue={searchValue || ''}
                  numberOfProductsShown={getNumberOfProductsShown()}
                  recentlyViewedIds={recentlyViewedIds}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
