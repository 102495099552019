import React, { useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { Image } from '~/elements/Image/Image'
import clsx from 'clsx'
import { NavigationElement } from '@contentfulTypes'
import { BottomSection } from './BottomSection'
import { ListItem } from './MobileFlyout'
import { useTranslation } from 'next-i18next'

type NextLevel = {
  children: React.ReactNode
  data: Omit<NavigationElement, 'contentfulMetadata'>
  firstItemExpander?: boolean
}

export const NextLevel = ({ children, data, firstItemExpander }: NextLevel) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const [animationFinished, setanimationFinished] = useState(true)
  const { t } = useTranslation()
  const [secondLevelStyles, nextLevelApi] = useSpring(() => ({
    transform: 'translateX(100%)',
  }))

  const openNextLevel = () => {
    nextLevelApi.start(() => ({
      transform: 'translateX(0%)',
      onStart: () => {
        setanimationFinished(false)
      },
    }))
  }

  const handleBackToTopLevel = () => {
    nextLevelApi.start(() => ({
      transform: 'translateX(100%)',
      onRest: () => {
        setanimationFinished(true)
      },
    }))
  }

  return (
    <div className={clsx('w-screen')}>
      <button
        className="flex w-full items-center justify-between lowercase"
        onClick={openNextLevel}
      >
        <div className="first-letter:uppercase">{data?.title}</div>
        <Image
          src={`/images/Icons/chevron-${firstItemExpander ? 'down' : 'right'}.svg`}
          width={24}
          height={24}
          placeholder="empty"
          alt="chevron-icon"
          className={'w-4'}
        />
      </button>
      {firstItemExpander ? (
        <div>{children}</div>
      ) : (
        <animated.div
          style={{ ...secondLevelStyles }}
          className={clsx(
            'rotate absolute left-0 bottom-0 right-0 top-0 z-10 w-screen translate-x-full overflow-y-auto',
            animationFinished && 'hidden',
          )}
          onScroll={(e: React.UIEvent<HTMLElement>) =>
            setHasScrolled((e.target as Element).scrollTop !== 0)
          }
        >
          <div className="relative left-0 bottom-0 right-0 top-[65px] flex min-h-[calc(100dvh-65px)] flex-col justify-between bg-primary-white">
            <div>
              <div
                className={clsx(
                  'sticky top-[65px] flex items-center bg-primary-white p-4 text-[18px] font-medium leading-4.5',
                  hasScrolled && 'shadow-[0px_0px_24px_rgba(0,0,0,0.15)]',
                )}
              >
                <button onClick={handleBackToTopLevel} className="flex w-full items-center gap-x-4">
                  <Image
                    src="/images/Icons/chevron-left.svg"
                    width={24}
                    height={24}
                    placeholder="empty"
                    alt="chevron-left-icon"
                    className="w-4"
                  />
                  <ListItem
                    item={{ title: t('all') } as NavigationElement}
                    naviLevel={'2nd_level'}
                  />
                </button>
              </div>
              {children}
            </div>
            <BottomSection />
          </div>
        </animated.div>
      )}
    </div>
  )
}
