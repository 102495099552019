import { useEffect, useCallback, useContext } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { useTranslation } from 'next-i18next'
import { Link } from 'elements/Link/Link'
import { Logo } from '../components/Logo'
import clsx from 'clsx'
import { Media } from '~/lib/Media'
import { AccountButton } from '../components/AccountButton'
import { Search } from '../../Search/Search'
import { Boop } from 'animations/Boop'
import { FirstLevel } from './components/FirstLevel'
import { SecondLevel } from './components/SecondLevel'
import { CartIcon } from '../components/CartIcon'
import { useRouter } from 'next/router'
import { UIContext } from '~/lib/Context/UIStore'
import { lockBodyScroll, freeBodyScroll } from '~/lib/scrollLock'
import { WishlistIcon } from '../components/WishlistIcon'

type Props = {}

export const DesktopHeader = ({}: Props) => {
  const {
    desktopNaviState: state,
    desktopNaviDispatch: dispatch,
    searchToggle,
  } = useContext(UIContext)
  const { t } = useTranslation()
  const router = useRouter()

  const closeSecondLevel = useCallback(() => {
    dispatch?.({ type: 'set-secondLevelToggle', payload: false })
  }, [dispatch])

  useEffect(() => {
    if (state?.secondLevelToggle || searchToggle) {
      lockBodyScroll()
    } else freeBodyScroll()
  }, [state?.secondLevelToggle, searchToggle])

  useEffect(() => {
    const handleRouteChange = () => {
      closeSecondLevel()
    }
    if (!state?.secondLevelToggle) return
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [closeSecondLevel, router.events, state?.secondLevelToggle])

  const scale = useSpring({
    transformOrigin: 'left top',
    transform: state?.scrolledPastMetabar ? 'scale(0.84)' : 'scale(1)',
  })

  return (
    <header
      className={clsx(
        'sticky top-[55px] z-40 hidden translate-y-0 print:hidden lgx:block',
        state?.scrolledPastMetabar && 'shadow-[0px_0px_24px_rgba(0,0,0,0.15)]',
      )}
      onMouseLeave={closeSecondLevel}
    >
      <div className="relative">
        <div className="flex items-center justify-between border-b border-grey-light bg-primary-white py-3 px-5 lg:py-6 lg:px-20 ">
          <Link href="/" className="self-end">
            <animated.div style={scale}>
              <Logo />
            </animated.div>
          </Link>
          <FirstLevel />
          <menu className="flex items-center gap-6">
            <li>
              <Search />
            </li>
            <li>
              <Boop>
                <Link href="/wishlist" title={t('wishlist')} prefetch={false}>
                  <WishlistIcon />
                </Link>
              </Boop>
            </li>
            <li>
              <AccountButton />
            </li>
            <li>
              <Boop>
                <Link href="/cart" title={t('Cart')} prefetch={false}>
                  <CartIcon />
                </Link>
              </Boop>
            </li>
          </menu>
        </div>
        <Media greaterThanOrEqual="lgx">
          <SecondLevel />
        </Media>
      </div>
    </header>
  )
}
