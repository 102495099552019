import { useEffect, useRef, useState, useContext } from 'react'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { DEFAULT_LOCALE } from '~/config/constants'
import { NavigationElement, useHeaderQuery } from '@contentfulTypes'
import { animated, useSpring } from '@react-spring/web'
import { Image } from '~/elements/Image/Image'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import clsx from 'clsx'
import { useMedia } from '~/hooks/useMedia'
import { NavItem } from './NavItem'
import { useRouter } from 'next/router'
import { UIContext } from '~/lib/Context/UIStore'
import { SearchFlyoutDesktop } from '~/modules/Search/SearchFlyoutDesktop'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

export const SecondLevel = ({}) => {
  const [isFullScreenFlyout, setIsFullScreenFlyout] = useState(false)
  const flyoutRef = useRef<HTMLDivElement>(null)
  const {
    desktopNaviState: state,
    desktopNaviDispatch: dispatch,
    searchValue,
    searchToggle,
    setSearchToggle,
  } = useContext(UIContext)
  const preview = usePreviewMode()
  const isDesktop2k = useMedia('2k')
  const isDesktop4k = useMedia('4k')

  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useHeaderQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const firstLevelData =
    data?.pageCollection?.items?.[0]?.mainMegaNavigation?.firstLevelItemsCollection?.items

  const firstLevelChildItems =
    firstLevelData &&
    state?.secondLevelIndex !== undefined &&
    firstLevelData?.[state?.secondLevelIndex]?.subElementsCollection?.items

  const bottomElement =
    firstLevelData &&
    state?.secondLevelIndex !== undefined &&
    firstLevelData?.[state?.secondLevelIndex]?.bottomElement

  // -------- Event handlers and animation: -----------

  const reveal = useSpring({
    delay: state?.secondLevelToggle && firstLevelChildItems ? 100 : 0,
    marginTop: state?.secondLevelToggle && firstLevelChildItems && !searchToggle ? '0px' : '0px',
    transform:
      (state?.secondLevelToggle && firstLevelChildItems) || searchToggle
        ? 'translateY(0%)'
        : 'translateY(-100%)',
  })

  const fade = useSpring({
    delay: (state?.secondLevelToggle && firstLevelChildItems) || searchToggle ? 300 : 0,
    opacity: (state?.secondLevelToggle && firstLevelChildItems) || searchToggle ? 0.6 : 0,
  })

  useEffect(() => {
    if (window && document) {
      ;(flyoutRef?.current &&
        flyoutRef?.current?.clientHeight + 177 >=
          (window.innerHeight || document.documentElement.clientHeight)) ||
      searchToggle
        ? setIsFullScreenFlyout(true)
        : setIsFullScreenFlyout(false)
    }
  }, [isFullScreenFlyout, searchToggle, state?.secondLevelIndex])

  return (
    <>
      <animated.div
        ref={flyoutRef}
        id="flyout"
        style={{
          ...reveal,
          maxHeight: isFullScreenFlyout
            ? searchToggle && isDesktop2k
              ? '50vh'
              : 'calc(100vh - 177px)'
            : 'unset',
          minHeight:
            isFullScreenFlyout && searchToggle
              ? isDesktop4k
                ? '50vh'
                : isDesktop2k
                ? '70vh'
                : 'calc(100vh - 177px)'
              : 'unset',
        }}
        className={clsx(
          'absolute -z-20 grid w-full grid-cols-4 grid-rows-1 gap-8 bg-primary-white',
          searchToggle && 'overflow-scroll px-20',
        )}
      >
        {searchToggle ? (
          <SearchFlyoutDesktop searchValue={searchValue ?? ''} />
        ) : (
          <>
            <nav
              className={clsx(
                'col-span-4 w-full overflow-y-auto px-20',
                bottomElement && bottomElement.title ? 'pt-10' : 'py-10',
              )}
              onFocus={() => dispatch?.({ type: 'set-secondLevelToggle', payload: true })}
            >
              <>
                {firstLevelChildItems ? (
                  <li className="relative grid grid-cols-6 gap-x-8">
                    {firstLevelChildItems.map((item, index) => {
                      if (!item) return null
                      return (
                        <div className="relative col-span-1" key={item?.sys?.id}>
                          {item?.categoriesCollection?.items?.map((category) => {
                            if (!category || !category?.title) return null
                            return (
                              <div className="mb-12 grid gap-y-2 last:mb-0" key={category?.sys?.id}>
                                <NavItem
                                  item={category as NavigationElement}
                                  className={
                                    'w-fit text-[16px] font-medium uppercase leading-[21px] text-secondary-soft-black'
                                  }
                                  naviLevel={'2rd_level'}
                                  id={index === 0 ? 'secondLevel' : ''}
                                />
                                {category?.subElementsCollection?.items?.map((categorySubItem) => {
                                  if (!categorySubItem || !categorySubItem?.title) return null
                                  return (
                                    <NavItem
                                      item={categorySubItem as NavigationElement}
                                      disableUnderlineOnHover={
                                        categorySubItem?.highlightedColor ? true : false
                                      }
                                      className="w-fit text-[14px] leading-4.5 text-secondary-soft-black"
                                      naviLevel={'2rd_level'}
                                      key={categorySubItem?.sys?.id}
                                    />
                                  )
                                })}
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </li>
                ) : null}
              </>
              {bottomElement && bottomElement.title ? (
                <li className="relative mt-[23px] grid grid-cols-6 gap-x-8 pt-[17px] pb-[23px] after:absolute after:top-0 after:-left-20 after:-right-20 after:block after:h-px after:border-t-[1px] after:border-grey-light after:content-['']">
                  <NavItem
                    item={bottomElement as NavigationElement}
                    id={bottomElement?.sys?.id}
                    disableUnderlineOnHover
                    className="col-span-1 col-start-6 w-fit text-[14px] font-medium uppercase leading-4.5 text-secondary-soft-black"
                    naviLevel={'2rd_level'}
                    key={'extra element'}
                  />
                </li>
              ) : null}
            </nav>
          </>
        )}
        <button
          onClick={() => {
            dispatch?.({ type: 'set-secondLevelToggle', payload: false })
            setSearchToggle?.(false)
          }}
          className={clsx('absolute right-5 top-10 h-10 w-10', !searchToggle && 'hidden')}
        >
          <Image
            src="/images/Icons/close.svg"
            width={24}
            height={24}
            placeholder="empty"
            alt="close-icon"
            className="h-full w-full rounded-full bg-grey-light p-2 duration-200 hover:bg-grey-medium"
          />
        </button>
      </animated.div>
      <animated.div
        style={fade}
        onMouseEnter={() => {
          setSearchToggle?.(false)
          dispatch?.({ type: 'set-secondLevelToggle', payload: false })
        }}
        className={clsx(
          'absolute -z-30 h-screen w-screen bg-primary-black',
          (state?.secondLevelToggle && firstLevelChildItems) || searchToggle ? 'block' : 'hidden',
        )}
      ></animated.div>
    </>
  )
}
