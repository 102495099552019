import { DesktopHeader } from './DesktopNavigation/DesktopHeader'
import { MobileHeader } from './MobileNavigation/MobileHeader'
import { DEFAULT_LOCALE } from 'config/constants'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useBanderoleQuery } from '@contentfulTypes'
import { useInView } from 'react-intersection-observer'
import { useContext, useEffect } from 'react'
import { UIContext } from '~/lib/Context/UIStore'
import { MobileBanderole } from './MobileNavigation/components/MobileBanderole'
import { DesktopBanderole } from './DesktopNavigation/components/DesktopBanderole'
import { Media } from '~/lib/Media'
import { StickyNewsletter } from './components/StickyNewsletter'
import clsx from 'clsx'

type Props = {}

export const Header = ({}: Props) => {
  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useBanderoleQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })
  const { desktopNaviDispatch: dispatch } = useContext(UIContext)
  const { ref: metabar, inView: inView } = useInView({
    threshold: 0.01,
    initialInView: true,
  })
  useEffect(() => {
    if (inView) {
      dispatch?.({ type: 'set-scrolledPastMetabar', payload: false })
    } else {
      dispatch?.({ type: 'set-scrolledPastMetabar', payload: true })
    }
  }, [inView, dispatch])

  const isFirstBanderoleSticky = data?.pageCollection?.items?.[0]?.banderole?.sticky
  const isSecondaryBanderoleSticky = data?.pageCollection?.items?.[0]?.secondaryBanderole?.sticky

  return (
    <>
      <div ref={metabar} />

      {/** Start - Desktop Headers Components **/}
      <Media
        className={clsx({
          'sticky top-0 z-50': isFirstBanderoleSticky || !isSecondaryBanderoleSticky,
        })}
        greaterThanOrEqual="lgx"
      >
        <DesktopBanderole banderoleData={data} />
      </Media>
      <Media
        className={clsx(
          'sticky z-40',
          isSecondaryBanderoleSticky && !isFirstBanderoleSticky ? 'top-0' : 'top-[55px]',
        )}
        greaterThanOrEqual="lgx"
      >
        <DesktopHeader />
      </Media>
      <Media
        className={clsx(
          'z-30',
          isSecondaryBanderoleSticky && !isFirstBanderoleSticky ? 'top-[90px]' : 'top-[146px]',
          { sticky: isSecondaryBanderoleSticky },
        )}
        greaterThanOrEqual="lgx"
      >
        <StickyNewsletter banderoleData={data} />
      </Media>
      {/** END - Desktop Headers Components **/}

      {/** Start - Mobile Headers Components **/}
      <Media
        className={clsx({
          'sticky top-0 z-40': isFirstBanderoleSticky || !isSecondaryBanderoleSticky,
        })}
        lessThan="lgx"
      >
        <MobileBanderole banderoleData={data} />
      </Media>
      <Media
        className={clsx(
          'sticky z-40',
          isSecondaryBanderoleSticky && !isFirstBanderoleSticky ? 'top-0' : 'top-[60px]',
        )}
        lessThan="lgx"
      >
        <MobileHeader />
      </Media>
      <Media
        className={clsx(
          'z-30',
          isSecondaryBanderoleSticky && !isFirstBanderoleSticky ? 'top-[109px]' : 'top-[170px]',
          { sticky: isSecondaryBanderoleSticky },
        )}
        lessThan="lgx"
      >
        <StickyNewsletter banderoleData={data} />
      </Media>
      {/** End - Mobile Headers Components **/}
    </>
  )
}
export default Header
