import { useCallback, useState, useEffect } from 'react'
import { KlevuKMCSettings } from '@klevu/core'
import { useTranslation } from 'next-i18next'
import { Link } from 'elements/Link/Link'
import useLocalStorage from 'hooks/useLocalStorage'
import styles from '../Search.module.css'
import { RecentlyViewedProducts } from './RecentlyViewedProducts'
import { PopularProducts } from './PopularProducts'
import { useMedia } from 'hooks/useMedia'
import clsx from 'clsx'

type Props = {
  recentlyViewedIds: string
  numberOfProductsShown: number
}
export const StartView = ({ recentlyViewedIds, numberOfProductsShown }: Props) => {
  const { t } = useTranslation()
  const isTablet = useMedia('md')
  const isDesktop = useMedia('lgx')
  const [currentStoredSearchTerms] = useLocalStorage('recentlySearchedTerms', '')
  const [popularSearchTerms, setPopularSearchTerms] = useState<string[]>([])

  const fetchPopularTerms = useCallback(async () => {
    const settings = await KlevuKMCSettings()
    if (settings?.root?.klevu_webstorePopularTerms?.length) {
      setPopularSearchTerms(settings.root.klevu_webstorePopularTerms)
    }
  }, [])

  useEffect(() => {
    fetchPopularTerms()
  }, [fetchPopularTerms])

  if (!popularSearchTerms.length) {
    return null
  }

  return (
    <>
      <div className="lgx:w-1/4">
        <h2 className={clsx(styles.searchHeading, 'pt-4 lgx:pt-0')}>{t('Search trends')}</h2>
        <ul className="mb-12 lgx:mb-16">
          {popularSearchTerms.map((trend) => (
            <li key={trend} className={styles.termLink}>
              <Link isUnderlinedOnHover title={`search-${trend}`} href={`/search?q=${trend}`}>
                {trend}
              </Link>
            </li>
          ))}
        </ul>
        <div className="">
          {!currentStoredSearchTerms ||
          (JSON.parse(currentStoredSearchTerms) &&
            !JSON.parse(currentStoredSearchTerms).length) ? null : (
            <>
              <h2 className={clsx(styles.searchHeading)}>{t('Recently searched')}</h2>
              <ul className="mb-12 lgx:mb-0">
                {JSON.parse(currentStoredSearchTerms).map((term: string) => (
                  <li className={styles.termLink} key={term}>
                    <Link isUnderlinedOnHover title={`search-${term}`} href={`/search?q=${term}`}>
                      {term}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      <div className={clsx('h-fit w-full pb-[52px] lgx:ml-12 lgx:pb-0')}>
        <PopularProducts
          numberOfProductsShown={
            recentlyViewedIds && JSON.parse(recentlyViewedIds)?.length
              ? numberOfProductsShown / 2
              : numberOfProductsShown
          }
          isDoubleLine={!(recentlyViewedIds && JSON.parse(recentlyViewedIds)?.length)}
        />
        <RecentlyViewedProducts
          product_ids={recentlyViewedIds && JSON.parse(recentlyViewedIds).slice().reverse()}
          maxNumberOfProductsShown={
            isDesktop || !isTablet ? numberOfProductsShown / 2 : numberOfProductsShown
          }
        />
      </div>
    </>
  )
}
