import { Image } from '~/elements/Image/Image'
import clsx from 'clsx'
import { StartView } from './StartView'
import { ResultsView } from './ResultsView'

type Props = {
  searchValue: string
  numberOfProductsShown: number
  onClose: () => void
  recentlyViewedIds: string
}

export const SearchMobileFlyout = ({
  searchValue,
  numberOfProductsShown,
  onClose,
  recentlyViewedIds,
}: Props) => {
  return (
    <div
      className={clsx(
        'flex h-screen w-screen flex-col overflow-scroll border-0 border-grey-border bg-primary-white pb-[150px] pt-4',
      )}
    >
      {!searchValue && !searchValue?.replace(/\s/g, '').length ? (
        <>
          <StartView
            numberOfProductsShown={numberOfProductsShown}
            recentlyViewedIds={recentlyViewedIds}
          />
        </>
      ) : (
        <ResultsView searchValue={searchValue} numberOfProductsShown={numberOfProductsShown} />
      )}
      <button onClick={onClose} className="absolute right-5 top-4 h-10 w-10">
        <Image
          src="/images/Icons/close.svg"
          width={24}
          height={24}
          placeholder="empty"
          alt="close-icon"
          className="h-full w-full rounded-full bg-grey-light p-2 duration-200 hover:bg-grey-medium"
        />
      </button>
    </div>
  )
}
