import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import { BanderoleQuery } from '@contentfulTypes'
import { BanderoleNewsletter } from '~/modules/BanderoleNewsletter/BanderoleNewsletter'
import clsx from 'clsx'
import ArrowRight from '../../components/Icons/ArrowRight'
import Link from 'next/link'
import Copy from '../../components/Icons/Copy'
import useCountdownData from '~/hooks/useCountdownData'
import BanderoleCountdown from '../../components/BanderoleCountdown'

type Props = {
  banderoleData?: BanderoleQuery
  onClick?: () => void
}

export const MobileBanderole = ({ banderoleData }: Props) => {
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)
  const [isPromoCodeCopied, setisPromoCodeCopied] = useState(false)
  const { t } = useTranslation()
  const countdownData = useCountdownData({
    isBanderoleVisible:
      banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.countDownDate &&
      !banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.hideCountDown,
    banderoleData: banderoleData?.pageCollection?.items[0]?.banderole?.countDown?.countDownDate,
    rerenderCondition: banderoleData?.pageCollection?.items,
  })

  const isCountdown = banderoleData?.pageCollection?.items[0]?.banderole?.countDown
  const isDiscountCode = isCountdown?.discountCode

  const copyToClipboard = () => {
    navigator?.clipboard?.writeText(isDiscountCode as string)
    setisPromoCodeCopied(true)
  }

  return (
    <>
      <div className="">
        <div
          className={clsx('print:hidden')}
          style={{
            background:
              (banderoleData?.pageCollection?.items[0]?.banderole?.backgroundColor as string) ??
              '#fff',
            color:
              (banderoleData?.pageCollection?.items[0]?.banderole?.textColor as string) ?? '#fff',
          }}
        >
          <div className="relative flex h-[60px] flex-col items-center justify-center px-4">
            {isCountdown ? (
              <div className="text-[14px] leading-[24px]">
                <button
                  className={clsx(isCountdown?.promoText && 'mr-1', 'inline')}
                  onClick={() => {
                    if (!isCountdown?.disableNewsletterPopup) setShowNewsletterPopup(true)
                  }}
                  dangerouslySetInnerHTML={{
                    __html: isCountdown?.newsletterText as string,
                  }}
                />
                {isCountdown?.promoText && (
                  <div
                    className="relative inline text-[14px] leading-[20px]"
                    dangerouslySetInnerHTML={{
                      __html: isCountdown?.promoText as string,
                    }}
                  />
                )}
                {isCountdown?.fullClick && (
                  <>
                    <Link href={isCountdown.fullClick} className="absolute inset-0" />
                    <ArrowRight
                      className="pointer-events-none absolute top-1/2 right-7 h-6 w-6 -translate-y-1/2"
                      stroke={
                        banderoleData?.pageCollection?.items[0]?.banderole?.textColor as string
                      }
                    />
                  </>
                )}
              </div>
            ) : null}
            {(!isCountdown?.hideCountDown || isDiscountCode) && (
              <div className="flex min-h-[24px] items-center justify-start md:justify-center">
                {isDiscountCode && (
                  <div>
                    <span className="mr-1 text-[14px] font-normal leading-[20px]">
                      {t('CODE')}:
                    </span>
                    <button
                      onClick={() => copyToClipboard()}
                      disabled={isPromoCodeCopied}
                      className="relative text-center disabled:text-primary-white"
                    >
                      <div className="flex w-full items-center justify-center uppercase">
                        <span className="text-p font-bold">
                          {!isPromoCodeCopied ? isDiscountCode : t('CODE COPYED')}
                        </span>
                        {!isPromoCodeCopied && <Copy className="ml-1 h-4 w-4" />}
                      </div>
                    </button>
                  </div>
                )}
                {countdownData.isReady && (
                  <BanderoleCountdown countdownData={countdownData} className="ml-4" />
                )}
                {countdownData.expired && (
                  <div className="ml-4 text-center text-p font-bold uppercase">{t('EXPIRED')}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <BanderoleNewsletter open={showNewsletterPopup} close={() => setShowNewsletterPopup(false)} />
    </>
  )
}
